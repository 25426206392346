<template>

  <div>

    <v-simple-table dense height="76vh">
      <!-- <thead>
        <tr>
          <th colspan="4"></th>
          <th colspan="4" class="text-center">Complete &amp; Cancelled Orders</th>
        </tr>
      </thead> -->
      <thead>
        <tr>
          <th>Month</th>
          <th>Month Commencing</th>
          <th class="text-center">Island</th>
          <th class="text-left">Status</th>
          <th class="text-right">Orders</th>
          <th class="text-right">Sales</th>
          <th class="text-right">Discount</th>
          <th class="text-right">Paid</th>
          <th class="text-right">Refunded</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in data" :key="index">
          <td>{{ row['Month'] }}</td>
          <td>{{ DisplayDate(row['MonthCommencing']) }}</td>
          <td class="text-center">{{ row['Island'] }}</td>
          <td class="text-left">{{ row['Complete'] }}</td>
          <td class="text-right">{{ row['Orders'] }}</td>
          <td class="text-right">{{ Money(row['Sales']) }}</td>
          <td class="text-right">{{ Money(row['Discount']) }}</td>
          <td class="text-right">{{ Money(row['Paid']) }}</td>
          <td class="text-right">{{ Money(row['Refunded']) }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-btn :enambed="this.data" class="mb-12 ma-2" fab absolute bottom right color="primary" @click="Save"><v-icon>{{ icons.mdiCloudDownload }}</v-icon></v-btn>

  </div>
</template>

<script>
import { mdiCloudDownload } from '@mdi/js';
import { DisplayDate, Money } from '@/datahelpers/displayTypes';
import axios from '@axios';
import { saveAs } from 'file-saver';
import * as CSV from 'csv-string';

export default {
  props: {
    year: {
      type: Number,
      required: false
    },
    weekly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      data: [],
      DisplayDate,
      Money,
      report: 'monthly-finance',
      icons: {
        mdiCloudDownload
      }
    }
  },
  methods: {
    LoadData() {

      axios.get(`${this.$store.state.apiUrl}/api/report/${this.report}/${this.year}`)
      .then(response => {
        this.data = response.data;
      })
      .catch(error => {
        console.error(error);
      })
    },
    Save() {
      let data = [];

        data.push(["Month", "Month Commencing", "Island", "Status", "Orders", "Sales", "Discount", "Paid", "Refunded"]);

        if (this.data && this.data.length > 0) {
          this.data.forEach((row) => {
            data.push([
              row['Month'],
              this.DisplayDate(row['MonthCommencing']),
              row['Island'],
              row['Complete'],
              row['Orders'],
              row['Sales'],
              row['Discount'],
              row['Paid'],
              row['Refunded']
            ]);
          });
        }

      let csv = CSV.stringify(data);

      const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
      saveAs(blob, `${this.year}-${this.report}-${new Date().toLocaleDateString()}.csv`);
    }
  },
  mounted() {
    this.LoadData();
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-data-table {
    min-height: 60vh;
  }
</style>