<template>
  <div>

  <v-simple-table dense height="76vh">
    <thead>
      <tr>
        <th v-if="weekly">Week No.</th>
        <th v-else>Month</th>
        <th v-if="weekly">Week Commencing</th>
        <th v-else>Month Commencing</th>
        <th class="text-center">Island</th>
        <th class="text-right">Complete Orders</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in data" :key="index">
        <td v-if="weekly">{{ row['WeekNo'] }}</td>
        <td v-else>{{ row['Month'] }}</td>
        <td v-if="weekly">{{ DisplayDate(row['WeekCommencing']) }}</td>
        <td v-else>{{ DisplayDate(row['MonthCommencing']) }}</td>
        <td class="text-center">{{ row['Island'] }}</td>
        <td class="text-right">{{ row['Count'] }}</td>
      </tr>
    </tbody>
  </v-simple-table>

  <v-btn :enambed="this.data" class="mb-12 ma-2" fab absolute bottom right color="primary" @click="Save"><v-icon>{{ icons.mdiCloudDownload }}</v-icon></v-btn>

  </div>
</template>

<script>
import { mdiCloudDownload } from '@mdi/js';
import { DisplayDate } from '@/datahelpers/displayTypes';
import axios from '@axios';
import { saveAs } from 'file-saver';
import * as CSV from 'csv-string';

export default {
  props: {
    year: {
      type: Number,
      required: false
    },
    weekly: {
      type: Boolean,
      default: false
    }
  },
  data() {

    let report = 'monthly-order-volume';
    if (this.weekly) report = 'weekly-order-volume';

    return {
      loading: true,
      data: [],
      DisplayDate,
      report: report,
      icons: {
        mdiCloudDownload
      }
    }
  },
  methods: {
    LoadData() {

      axios.get(`${this.$store.state.apiUrl}/api/report/${this.report}/${this.year}`)
      .then(response => {
        this.data = response.data;
      })
      .catch(error => {
        console.error(error);
      })
    },
    Save() {
      let data = [];

      if (this.weekly) {
        data.push(["Week No.", "Week Commencing", "Island", "Complete Orders"]);

        if (this.data && this.data.length > 0) {
          this.data.forEach((row) => {
            data.push([
              row['WeekNo'],
              this.DisplayDate(row['WeekCommencing']),
              row['Island'],
              row['Count']
            ]);
          });
        }
      } else {
        data.push(["Month", "Month Commencing", "Island", "Complete Orders"]);

       if (this.data && this.data.length > 0) {
          this.data.forEach((row) => {
            data.push([
              row['Month'],
              this.DisplayDate(row['MonthCommencing']),
              row['Island'],
              row['Count']
            ]);
          });
        }
      }

      let csv = CSV.stringify(data);

      const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
      saveAs(blob, `${this.year}-${this.report}-${new Date().toLocaleDateString()}.csv`);
    }
  },
  mounted() {
    this.LoadData();
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-data-table {
    min-height: 60vh;
  }
</style>