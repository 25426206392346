<template>

  <v-card>
    <v-card-title>{{ year }} Reporting</v-card-title>
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      fixed-tabs icons-and-text
    >
      <v-tab v-for="(tab, index) in tabs" :key="index">
        {{ tab.title }}
        <v-icon size="20">{{ tab.icon }}</v-icon>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <order-volume :year="year" :weekly="true" />
      </v-tab-item>
      <v-tab-item>
        <order-volume :year="year" :weekly="false" />
      </v-tab-item>
      <v-tab-item>
        <finance :year="year" />
      </v-tab-item>
      <v-tab-item>

          <v-tabs v-model="monthTab" vertical>
            <v-tab v-for="(month, index) in months" :key="index">
              {{ month }}
            </v-tab>

            <v-tab-item v-for="(month, index) in months" :key="index">
              <monthly-orders :year="year" :month="index + 1" />
            </v-tab-item>

          </v-tabs>

      </v-tab-item>
      <v-tab-item>
        <archive />
      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>

<script>
import store from '@/store'
import {
  mdiPoll,
  mdiFinance,
  mdiTableLarge,
  mdiArchive,
} from '@mdi/js'
import { DateTime } from '@/datahelpers/displayTypes';
import OrderVolume from './OrderVolume'
import Finance from './Finance'
import MonthlyOrders from './MonthlyOrders'
import Archive from './Archive'

export default {
  components: {
    OrderVolume,
    Finance,
    MonthlyOrders,
    Archive
  },
  data() {

    let year = parseInt(this.$route.params.year);
    if (!year || isNaN(year)) year = new Date().getFullYear();

    const tabs = [
      { title: 'Weekly Volume', icon: mdiPoll },
      { title: 'Monthly Volume', icon: mdiPoll },
      { title: 'Monthly Finance', icon: mdiFinance },
      { title: 'Monthly Orders', icon: mdiTableLarge },
      { title: 'Archive', icon: mdiArchive }
    ]

    return {
      tab: "Orders",
      tabs: tabs,
      year: year,
      months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      monthTab: new Date().getMonth()
    }
  }
}
</script>
