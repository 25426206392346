<template>
  <div>
    <v-simple-table dense height="76vh">
      <thead>
        <tr>
          <th>Member</th>
          <th>Reference</th>
          <th>Checkout</th>
          <th>Order Date</th>
          <th>Product</th>
          <th>Status</th>
          <th class="text-center">Island</th>
          <th class="text-right">Item Value</th>
          <th class="text-right">Retailer</th>
          <th class="text-right">S2M Charge</th>
          <th class="text-right">Discount Code</th>
          <th class="text-right">Discount</th>
          <th class="text-right">After Discount</th>
          <th class="text-right">Paid</th>
          <th class="text-right">Refunded</th>
          <th class="text-right">Extras Total</th>
          <template v-if="extras">
          <th v-for="(extra, index) in extras" :key="index" class="text-right">
            {{ extra.Name}}
          </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order, index) in orders" :key="index">
          <td>{{ order['MemberId_FullName'] }}</td>
          <td>{{ order['Reference'] }}</td>
          <td>{{ order['CheckoutReference'] }}</td>
          <td>{{ DisplayDate(order['Created']) }}</td>
          <td>{{ order['OrderTypeShort'] }}</td>
          <td>{{ order['StatusId_Name'] }}</td>
          <td>{{ order['DeliveryAddressId_CountryId_Name'] }}</td>
          <td>{{ Money(order['ItemValue']) }}</td>
          <td>{{ order['RetailerName'] }}</td>
          <td class="text-right">{{ Money(PriceForOrder(order)) }}</td>
          <td>{{ order['DiscountCode'] }}</td>
          <td class="text-right">{{ Money(DiscountForOrder(order)) }}</td>
          <td class="text-right">{{ Money(AfterDiscountForOrder(order)) }}</td>
          <td class="text-right">{{ Money(PaidForOrder(order)) }}</td>
          <td class="text-right">{{ Money(RefundedForOrder(order)) }}</td>
          <td class="text-right">{{ Money(ExtrasForOrder(order)) }}</td>
          <template v-if="extras">
          <td v-for="(extra, index) in extras" :key="index" class="text-right">
            <template v-if="OrderHasExtra(order, extra.Id)">
            {{ Money(extra.Price) }}
            </template>
          </td>
          </template>
        </tr>
      </tbody>
    </v-simple-table>

    <v-btn :enabled="this.orders" class="mb-12 ma-2" fab absolute bottom right color="primary" @click="Save"><v-icon>{{ icons.mdiCloudDownload }}</v-icon></v-btn>

  </div>
</template>

<script>
import { DisplayDate, Money, DateTime } from '@/datahelpers/displayTypes';
import axios from '@axios';
import { mdiCloudDownload } from '@mdi/js';
import { saveAs } from 'file-saver';
import * as CSV from 'csv-string';


export default {
  props: {
    year: {
      type: Number,
      required: true
    },
    month: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      orders: [],
      invoices: {},
      extras: {},
      DisplayDate,
      Money,
      report: 'monthly-orders',
      icons: {
        mdiCloudDownload
      }
    }
  },
  methods: {
    LoadData() {

      axios.get(`${this.$store.state.apiUrl}/api/report/${this.report}/${this.year}/${this.month}`)
      .then(response => {
        this.orders = response.data.Orders;
        this.invoices = response.data.Invoices;
        this.extras = response.data.Extras;
      })
      .catch(error => {
        console.error(error);
      })
    },
    OrderHasExtra(order, extraId) {

      if (order.ExtrasIds.includes(extraId)) {
        return true;
      }

      if (this.invoices[order['Id']] && this.invoices[order['Id']].ExtraIds.includes(extraId)) {
        return true;
      }

      return false;
    },
    DiscountForOrder(order) {

      let amount = 0;

      if (this.invoices[order['Id']]) {
        amount = this.invoices[order['Id']].Discount;
      }

      return amount;
    },
    AfterDiscountForOrder(order) {

      return this.PriceForOrder(order) - this.DiscountForOrder(order);
    },
    PaidForOrder(order) {

      let amount = 0;

      if (this.invoices[order['Id']]) {
        amount = this.invoices[order['Id']].Paid;
      }

      return amount;
    },
    RefundedForOrder(order) {

      let amount = 0;

      if (this.invoices[order['Id']]) {
        amount = this.invoices[order['Id']].Refunded;
      }

      return amount;
    },
    PriceForOrder(order) {

      let amount = 0;

      if (this.invoices[order['Id']]) {
        amount = this.invoices[order['Id']].Price;
      }

      return amount;
    },
    ExtrasForOrder(order) {

      let amount = 0;

      if (this.invoices[order['Id']]) {
        amount = this.invoices[order['Id']].Extras;
      }

      // check actual extras for old data
      if (amount == 0 && ((order.ExtraIds && order.ExtraIds.length > 0) || (this.invoices[order['Id']] && this.invoices[order['Id']].ExtraIds && this.invoices[order['Id']].ExtraIds.length > 0))) {

        let sum = 0;
        this.extras.forEach(extra => {
          if (this.OrderHasExtra(order, extra.Id)) {
            sum = sum + extra.Price;
          }
        });
        return sum;
      }

      return amount;
    },
    Save() {
      let data = [];

      let fieldNames = ["Member", "Reference", "Checkout Reference", "Order date", "Product", "Status", "Island", "Item Value", "Retailer", "S2M Charge", "Discount Code", "Discount", "After Discount", "Paid", "Refunded", "Extras Total"];
      this.extras.forEach(extra => {
        fieldNames.push(extra.Name);
      });
      data.push(fieldNames);

      if (this.orders && this.orders.length > 0) {
        this.orders.forEach((row) => {
          let rowData = [
            row['MemberId_FullName'],
            row['Reference'],
            row['CheckoutReference'],
            DateTime(row['Created']),
            row['OrderTypeShort'],
            row['StatusId_Name'],
            row['DeliveryAddressId_CountryId_Name'],
            row['ItemValue'],
            row['RetailerName'],
            this.PriceForOrder(row),
            row['DiscountCode'],
            this.DiscountForOrder(row),
            this.AfterDiscountForOrder(row),
            this.PaidForOrder(row),
            this.RefundedForOrder(row),
            this.ExtrasForOrder(row)
          ];
          this.extras.forEach(extra => {
            if (this.OrderHasExtra(row, extra.Id)) {
              rowData.push(extra.Price);
            } else {
              rowData.push('');
            }
          });
          data.push(rowData);
        });
      }

      let csv = CSV.stringify(data);

      const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
      saveAs(blob, `${this.year}-${this.report}-${new Date().toLocaleDateString()}.csv`);
    }
  },
  mounted() {
    this.LoadData();
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-data-table {
    min-height: 60vh;
  }
</style>