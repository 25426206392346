<template>

  <v-card>
    <v-card-text>
      <v-list>
        <v-list-item-group v-model="selectedYearIndex">
          <v-list-item v-for="year in years" :key="year" :to="{ name: 'reports-year', params: { year: year } }">
            <v-list-item-content>
              <v-list-item-title @click="goYear(year)">
                {{ year }}
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    let selectedYear = parseInt(this.$route.params.year)
    if (!selectedYear || isNaN(selectedYear)) selectedYear = new Date().getFullYear()

    let currentYear = new Date().getFullYear()

    let selectedYearIndex = 0
    let years = []

    let startYear = 2022
    for (var i = 0; i + startYear <= currentYear; i++) {
      years.push(i + startYear)
      if (i + startYear == selectedYear) {
        selectedYearIndex = i
      }
    }

    function goYear(year) {
      //this.$router.push({ name: 'reports', params: { year: year } })
      window.location.href = '/reports/' + year
    }

    return {
      years: years,
      selectedYearIndex: selectedYearIndex,
      goYear,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
::v-deep .v-list {
  min-height: 60vh;
}
</style>